import hsLogo from '@images/hsmm_logo.png';

interface PropTypes {
  width?: string | number;
  height?: string | number;
}

export function CompanyLogoWide({ width = '194px', height = '26px' }: PropTypes) {
  return <img width={width} height={height} src={hsLogo} alt="HealthSherpa Medicare" />;
}
